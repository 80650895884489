import TestGithubStorage from '../components/misc/GithubStorage';

export default function PlaygroundPage() {
  return (
    <>
      <h1>Playground</h1>
      <TestGithubStorage />
    </>
  );
}
